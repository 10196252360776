import * as React from 'react'
import { Helmet } from 'react-helmet'
import { SeoData } from './model'

const SeoComponent: React.FC<SeoData> = ({
  title,
  description,
  ogImageUrl,
  ogType,
  metaKeywords,
  noIndex,
  canonicalUrl,
  favicon
}) => {
  return (
    <Helmet defaultTitle="ExtraHut">
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {ogImageUrl && <meta property="og:image" content={ogImageUrl} />}
      {ogType && <meta property="og:type" content={ogType} />}
      {metaKeywords && <meta property="keywords" content={metaKeywords} />}
      {noIndex === 'true' && <meta name="robots" content="noindex" />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {favicon && <link rel="icon" type="image/png" href={favicon} />}
    </Helmet>
  )
}

export default SeoComponent

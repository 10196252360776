module.exports = {
  purge: {
    content: ['./src/**/*.{js,jsx,ts,tsx}']
  },
  darkMode: false,              // or 'media' or 'class'
  theme: {
    fontFamily: {
      main: ['Satoshi', 'Helvetica', 'sans-serif'],
      paragraph: ['Satoshi', 'Helvetica', 'sans-serif']
    },
    fontSize: {
      'xxl': '8vw',             //160px at a width of 2000px
      'xl': '3.5vw',            //70px at a width of 2000px
      'l': '1.75vw',            //35px at a width of 2000px
      'ml': '1.5vw',            //30px at a width of 2000px
      'm': '1.25vw',            //25px at a width of 2000px
      's': '1vw',               //20px at a width of 2000px
      'xs': '0.75vw',           //15px at a width of 2000px
      'xxs': '0.5vw',           //10px at a width of 2000px
      'mobile-xxxl': '32vw',    //160px at a width of 500px
      'mobile-xxl': '16vw',     //80px at a width of 500px
      'mobile-xl': '12vw',      //60px at a width of 500px
      'mobile-l': '7vw',        //35px at a width of 500px
      'mobile-ml':'6vw',        //30px at a width of 500px
      'mobile-m': '5vw',        //25px at a width of 500px
      'mobile-s': '4vw',        //20px at a width of 500px
      'mobile-xs': '3vw',       //15px at a width of 500px
      'mobile-xxs': '2vw'       //10px at a width of 500px
    },
    lineHeight: {
      tight: '0.9em',
      normal: '1.1em',
      loose: '1.4em',
    },
    letterSpacing: {
      tight: '-0.02em',
      normal: 0,
      loose: '0.02em',
    },
    extend: {
      colors: {
        primary: '#0000EE',
        black: '#0F0F0F',
        white: '#FFFFFF',
        accent: '#D72521',
        accent2: '#13B771',
        separator: '#2F2F2F',
        gray: {
          90: '#2F2F2F',
          80: '#464646',
          70: '#565656',
          60: '#747474',
          50: '#868686',
          40: '#A2A2A2',
          30: '#B6B6B6',
          20: '#D0D0D0',
          10: '#E6E6E6',
          5:  '#F2F2F2' 
        }
      },
      cursor: {
        grabbing: 'grabbing',
      },  
      animation: {
        fadeIn: 'fadeIn 0.5s ease-in-out forwards',
        fadeOut: 'fadeOut 0.5s ease-in-out forwards'
      },
      keyframes: {
        fadeIn: {
          '0%': { visibility: 'visible' },
          '1%': { opacity: 0 },
          '100%': { opacity: 1 }
        },
        fadeOut: {
          '0%': { opacity: 1 },
          '99%': { opacity: 0 },
          '100%': { visibility: 'hidden' }
        },
      },
      spacing: {
        xxxs: '0.5vw',                  //10px at a width of 2000px
        xxs: '0.75vw',                  //15px at a width of 2000px
        xs: '1vw',                      //20px at a width of 2000px
        s: '2vw',                       //40px at a width of 2000px
        m: '4vw',                       //80px at a width of 2000px
        ml:'5vw',                       //100px at a width of 2000px
        l: '6vw',                       //120px at a width of 2000px
        xl: '10vw',                     //200px at a width of 2000px
        xxl: '16vw',                    //320px at a width of 2000px
        xxxl: '36vw',                   //720px at a width of 2000px
        xxxxl: '48vw',                  //960px at a width of 2000px
        'mobile-xxxs': '2vw',           //10px at a width of 500px
        'mobile-xxs': '3vw',            //15px at a width of 500px
        'mobile-xs': '4vw',             //20px at a width of 500px
        'mobile-s': '8vw',              //40px at a width of 500px
        'mobile-m': '16vw',             //80px at a width of 500px
        'mobile-ml':'20vw',             //100px at a width of 500px
        'mobile-l': '24vw',             //120px at a width of 500px
        'mobile-xl': '40vw',            //200px at a width of 500px
        'mobile-xxl': '64vw',           //320px at a width of 500px
        'mobile-xxxl': '100vw',         //500px at a width of 500px
        'mobile-xxxxl': '190vw',        //950px at a width of 500px
        'mobile-foot-grid': '308vw',
        'mobile-foot-gap': '12vw',
        'mobile-menu-grid': '340vw',
        'desktop-28': '28vw',            //560px at a width of 2000px
        'desktop-20': '20vw',            //400px at width of 2000px
        'menu': '101%'                   //to hide top menu
      },
      screens: {
        'desktop': '1024px',
      },
      zIndex: {
        '-10': '-10',
      },
      opacity: {
        'separator': '0.1',
        'alt': '0.2'
      }
    },
  },
  variants: {
    extend: {
      scale: ['hover', 'group-hover'],
      translate: ['hover', 'group-hover'],
      display: ['hover', 'group-hover'],
      opacity: ['disabled'],
      backgroundColor: ['active', 'disabled'],
      cursor: ['disabled'],
      borderColor: ['active', 'disabled'],
      textColor: ['active', 'disabled'],
    },
  },
  plugins: []
}

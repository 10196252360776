import _get from 'lodash/get'
import { DynamicSitesPath } from './typings/enums'
import { format as formatDate, isDate } from 'date-fns'
import { enGB as en } from 'date-fns/locale'

interface LocalizedItem extends KontentItem {
  preferred_language: string
}

export const filterPageDataByLanguage = (data: LocalizedItem[], pageCodename: string, sectionCodename: string, language: string, element = 'content') => {
  const nodes = _get(data, 'nodes', [])
  const pageData = nodes.find((node: LocalizedItem) => node?.system?.codename === pageCodename)
  const sectionsData = _get(pageData, `elements.${element}.value`, [])
  const filteredDataByLanguage = sectionsData.filter((section: LocalizedItem) => {
    return section?.preferred_language === language
  })
  let filteredData = filteredDataByLanguage
  if (sectionCodename) {
    const filteredDataBySectionCodeName = filteredData.filter((section: LocalizedItem) => {
      return section?.system?.codename === sectionCodename
    })
    filteredData = filteredDataBySectionCodeName
  }

  return _get(filteredData, '[0].elements', '')
}

export const filterSectionsByLanguage = (data: LocalizedItem[], pageCodename: string, language: string) => {
  const nodes = _get(data, 'nodes', [])
  const pageData = nodes.find((node: LocalizedItem) => node?.system?.codename === pageCodename)
  const sectionsData = _get(pageData, 'elements.content.value', [])
  const filteredData = sectionsData.filter((section: LocalizedItem) => {
    return section?.preferred_language === language
  })
  return filteredData
}

export const filterSeoDataByLanguage = (data: LocalizedItem[], pageCodename: string, language: string) => {
  const nodes = _get(data, 'allKontentItemPage.nodes', [])
  const pageSeoData = nodes.find((node: LocalizedItem) => {
    return node?.system?.codename === pageCodename && node?.preferred_language === language
  })
  return {
    ..._get(pageSeoData, 'elements', ''),
    siteMeta: _get(data, 'site.siteMetadata', {})
  }
}

export const filterElementDataByLanguage = (data: LocalizedItem[], language: string, path: DynamicSitesPath) => {
  const securedData = _get(data, path, [])
  const filteredData = securedData.filter((collection: LocalizedItem) => collection.preferred_language === language)
  return filteredData
}

export const readingTime = (text: string): number => {
  const LETTERS_PER_MINUTE = 200
  const letters = text.trim().replace(/<[^>]*>?/gm, '').split(/\s+/).length
  return Math.ceil(letters / LETTERS_PER_MINUTE)
}

export const convertToSlug = (text: string) => {
  return text.trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
}

export const localizeDate = (date: Date | undefined, lang: string) => {
  const locales: {[key: string]: Locale} = { en }
  if (date && isDate(date)) {
    const locale = locales[lang] || en
    return formatDate(date, 'PP', { locale })
  }
  return ''
}

export const decimalCount = (number: number) => {
  const numberAsString = String(number)
  if (numberAsString.includes('.')) {
    return numberAsString.split('.')[1].length
  }
  return 0
}

import * as React from 'react'
import { RichTextElement } from '@kentico/gatsby-kontent-components'
import { Link as GatsbyLink } from 'gatsby'
import './style.scss'

export interface LinkProps {
    url: string
    title?: React.ReactNode
    target?: string
    className?: string
}

const Link: React.FC<LinkProps> = ({
  url, 
  title,
  children,
  target,
  ...rest
}) => {
  if (process.env.GATSBY_SITE_URL 
  && url.startsWith(process.env.GATSBY_SITE_URL)
  && target !== '_blank') {
    url = url.replace(process.env.GATSBY_SITE_URL, '/')
  }
  const isRelativeUrl = (url: string): boolean => /^\/(?!\/)/.test(url)
  if (isRelativeUrl(url) && target !== '_blank') {
    return (
      <GatsbyLink
        to={url}
        state={{ prevPath: typeof window !== 'undefined' ? window.location.pathname : '' }}
        {...rest}
      >
        {children || <RichTextElement value={title as string} />}
      </GatsbyLink>
    )
  }
  return (
    <a 
      href={url}
      target={target ? target : '_blank'}
      rel="noopener noreferrer"
      {...rest}
    >
      {children || <RichTextElement value={title as string} />}
    </a>
  )
}

export default Link
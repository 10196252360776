import React from 'react'
import { AnimationService } from '../common/AnimationService'
import { useMobile } from '../hooks/useMobile'
import './style.scss'

function withSSR<T>(PageTemplate: React.FC<T>) {
  const WrappedPageTemplate = (props: Omit<T, 'isMobile'>) => {
    const { isMobile } = useMobile()

    React.useEffect(() => {
      AnimationService.refreshScrollTrigger()
    }, [])

    return   (
      <>
        <div className="desktop-page">
          {(isMobile === undefined || !isMobile) && <PageTemplate isMobile={false} {...(props as T)} />}
        </div>
        <div className="mobile-page">
          {(isMobile === undefined || isMobile) && <PageTemplate isMobile={true} {...(props as T)} />}
        </div>
      </>
    )  
  }

  return WrappedPageTemplate
}

export default withSSR
import { graphql, useStaticQuery } from 'gatsby'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Navbar */
export interface KenticoNavbar extends KontentItem {
  logo: KontentAssetElement
  menu_logo: KontentAssetElement
  logo_clickable: KontentTextElement
  show_hamburger_icon: KontentTextElement
  contact_name: KontentTextElement
  contact_phone: KontentTextElement
  contact_email: KontentTextElement
  calendly_text: KontentTextElement
  calendly_url: KontentTextElement
  button_text: KontentTextElement
  button_link: KontentTextElement
  avatar_photo_or_video: KontentAssetElement
}

export interface ContactData {
  contactName: string
  contactPhone: string
  contactEmail: string
  photo: ImageItem
  videoUrl: string
  position: string
}

export class Navbar {
  logo: ImageItem
  menuLogo: ImageItem
  isLogoClickable: boolean
  showHamburgerIcon: boolean
  contactData: ContactData
  calendlyText: string
  calendlyUrl: string
  buttonText: string
  buttonLink: string
  avatarPhoto: ImageItem
  avatarVideoUrl: string
  pathname: string

  constructor(data: KenticoNavbar, pathname: string) {
    this.logo = _get(data, 'logo.value[0]', '')
    this.menuLogo = _get(data, 'menu_logo.value[0]', '')
    this.isLogoClickable = _get(data, 'logo_clickable.value[0].name', '') === 'Yes'
    this.showHamburgerIcon = _get(data, 'show_hamburger_icon.value[0].name', '') === 'Yes'
    const contactAssetType = _get(data, 'contact_person.value[0].elements.photo_or_video.value[0].type', '')
    this.contactData = {
      contactName: _get(data, 'contact_person.value[0].elements.name.value', ''),
      contactPhone: _get(data, 'contact_person.value[0].elements.mobile.value', ''),
      contactEmail: _get(data, 'contact_person.value[0].elements.email.value', ''),
      photo: contactAssetType.includes('image') ? _get(data, 'contact_person.value[0].elements.photo_or_video.value[0]') : '',
      videoUrl: contactAssetType.includes('video') ? _get(data, 'contact_person.value[0].elements.photo_or_video.value[0].url') : '',
      position: _get(data, 'contact_person.value[0].elements.position.value', ''),
    }
    this.calendlyText = _get(data, 'calendly_text.value', '')
    this.calendlyUrl = _get(data, 'calendly_url.value', '')
    this.buttonText = _get(data, 'button_text.value', '')
    this.buttonLink = _get(data, 'button_link.value', '')
    const avatarType = _get(data, 'avatar_photo_or_video.value[0].type', '')
    this.avatarPhoto = avatarType.includes('image') ? _get(data, 'avatar_photo_or_video.value[0]') : '',
    this.avatarVideoUrl = avatarType.includes('video') ? _get(data, 'avatar_photo_or_video.value[0].url') : '',
    this.pathname = pathname
  }
}

const navbarFragment = graphql`
query NavbarQuery {
  allKontentItemPage {
    nodes {
      elements {
        navbar {
          value {
            ... on kontent_item_navbar {
              elements {
                ...kontent_item_navbar_elementsFragment
              }
              preferred_language
              system {
                codename
              }
            }
          }
        }
      }
      system {
        codename
      }
    }
  }
}`

export const navbarElementsFragment = graphql`
  fragment kontent_item_navbar_elementsFragment on kontent_item_navbar_elements {
    logo {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
    menu_logo {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
    logo_clickable {
      value {
        name
      }
    }
    show_hamburger_icon {
      value {
        name
      }
    }
    contact_person {
      value {
        ... on kontent_item_employee_element {
          elements {
            email {
              value
            }
            mobile {
              value
            }
            name {
              value
            }
            photo_or_video {
              value {
                url
                description
                height
                name
                size
                type
                width
              }
            }
            position {
              value
            }
          }
        }
      }
    }
    calendly_text {
      value
    }
    calendly_url {
      value
    }
    button_text {
      value
    }
    button_link {
      value
    }
    avatar_photo_or_video {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
  }
`

export const useNavbarData = (language: string, pageCodename: string, pathname: string) => {
  const data = useStaticQuery(navbarFragment)
  const navbarData: KenticoNavbar = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, '', language, 'navbar')
  return new Navbar(navbarData, pathname)
}
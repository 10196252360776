import * as React from 'react'
import { RichTextElement } from '@kentico/gatsby-kontent-components'
import Link from '../../Link'
import { LinksColumn as LinksColumnItems } from '../model'
import './style.scss'

export interface LinksColumnColors {
  textColor: string
  borderColor: string
  linkHoverColor: string
}
interface LinksColumnProps extends LinksColumnItems, LinksColumnColors { }

const LinksColumn = React.forwardRef<HTMLDivElement, LinksColumnProps>((props: LinksColumnProps, ref) => {
  const {
    title,
    links,
    textColor,
    borderColor,
    linkHoverColor
  } = props
  return (
    <div ref={ref} className={`col-span-1 ${textColor}`}>
      <header className="pb-mobile-s lg:pb-s FontS">
        {title}
      </header>
      <div className={`pb-mobile-xxxs lg:pb-xxxs border-b ${borderColor}`}></div>
      <div className="pt-mobile-xxxs lg:pt-xxxs"></div>
      <ul className="FontM"
      >
        {links.map((link, i) => {
          return (
            <li
              key={i}
              className="pt-mobile-xs lg:pt-xs"
            >
              {link.url
                ? <Link
                  className={`hover:${linkHoverColor} hover:opacity-60`}
                  url={link.url}
                  title={link.title}
                />
                : <RichTextElement value={link.title} />}
            </li>
          )
        })}
      </ul>
    </div>
  )
})
LinksColumn.displayName = 'LinksColumn'

export default LinksColumn

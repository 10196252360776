import * as React from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config.js'

interface ConfigOption {
  [key: string]: string
}

export type MobileContextType = {
  isMobile: boolean | undefined
}

export const useMobile = (): MobileContextType => {
  const [isMobile, setIsMobile] = React.useState<boolean>()
  const fullConfig = resolveConfig(tailwindConfig)
  const minDesktopResolution = (fullConfig.theme.screens as ConfigOption)['desktop']

  React.useEffect(() => {
    const mediaQuery = window.matchMedia(`(min-width: ${minDesktopResolution})`)
    const mediaQueryHandler = () => {
      setIsMobile(!mediaQuery.matches)
    }
    if (isMobile === undefined) {
      mediaQueryHandler()
    }
    mediaQuery.addEventListener('change', mediaQueryHandler)

    return () => mediaQuery.removeEventListener('change', mediaQueryHandler)
  }, [isMobile, minDesktopResolution])

  return {
    isMobile
  }
}

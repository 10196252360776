import * as React from 'react'
import { IsMobileContext } from '../../common/Context'
import './style.scss'

export interface ButtonProps {
  title: string
  onClick: () => void
}

interface StyledButtonProps extends ButtonProps {
  className?: string
  disabled?: boolean
}

const Button = ({
  title,
  className,
  onClick,
  disabled,
}: StyledButtonProps) => {
  const { isMobile } = React.useContext(IsMobileContext)
  const buttonStyle = isMobile
    ? {height: '10vw', paddingLeft: '6vw', paddingRight: '6vw'}
    : {height: '2.5vw', paddingLeft: '1.5vw', paddingRight: '1.5vw'}

  return (
    <div className="Button z-10">
      <button 
        className={`rounded-full ${className || ''}`}
        style={buttonStyle}
        onClick={onClick}
        disabled={disabled}
      >
        {title}
      </button>
    </div>
  )
}

export default Button

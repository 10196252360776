import * as React from 'react'
import { navigate } from 'gatsby'
import { ImageElement } from '@kentico/gatsby-kontent-components'
import { IsMobileContext } from '../../../common/Context'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Nav from '../Nav'
import LinksGrid from '../../LinksGrid'
import { ButtonType } from '../../../common/typings/enums'
import { LinksGrid as LinksGridClass } from '../../LinksGrid/model'
import { Navbar as NavbarClass } from '../model'
import { ArrowDirection, ArrowColor, ImageQuality } from '../../../common/typings/enums'
import ArrowButton from '../../ArrowButton'
import './style.scss'

export interface MenuAllProps extends LinksGridClass, NavbarClass {
  buttonType?: ButtonType
  visible: boolean
  setMenuVisible: (newState: boolean) => void
}

type MenuProps = Omit<MenuAllProps, 'avatarPhoto' | 'avatarVideoUrl'>;

const Menu: React.FC<MenuProps> = ({
  visible,
  setMenuVisible,
  linksColumns,
  logo,
  menuLogo,
  isLogoClickable,
  showHamburgerIcon,
  contactData,
  calendlyText,
  calendlyUrl,
  buttonText,
  buttonLink,
  buttonType,
  pathname,
}) => {
  const { t } = useTranslation()
  const { isMobile } = React.useContext(IsMobileContext)
  const { contactName, contactPhone, contactEmail, photo, videoUrl } = contactData
  const menuColumns = [...linksColumns]
  menuColumns.splice(2, 1)
  const imageQualityOptions = React.useMemo(() => ({ quality: ImageQuality.OPTIMIZED }), [])
  const imageStyle = React.useMemo(() => ({ width: '24vw' }), [])
  const videoStyle = React.useMemo(() => ({ aspectRatio: '1/1' }), [])

  const sliderRef = React.useRef<HTMLDivElement>(null)
  const [sliderState, setSliderState] = React.useState({
    isDown: false,
    startX: 0,
    scrollLeft: 0
  })

  const mouseDownHandler = (e: React.MouseEvent) => {
    if (sliderRef.current) {
      setSliderState({
        isDown: true,
        startX: e.pageX - sliderRef.current.offsetLeft,
        scrollLeft: sliderRef.current.scrollLeft
      })
    }
  }

  const mouseLeaveOrUpHandler = () => {
    setSliderState(prevState => ({
      ...prevState,
      isDown: false,
    }))
  }

  const mouseMoveHandler = (e: React.MouseEvent) => {
    if (sliderRef.current && sliderState.isDown) {
      e.preventDefault()
      const newX = e.pageX - sliderRef.current.offsetLeft
      const distanceX = (newX - sliderState.startX) * 2
      sliderRef.current.scrollLeft = sliderState.scrollLeft - distanceX
    }
  }

  const isHTMLAnchor = (element: HTMLElement | null) => element && (element.nodeName === 'A')
  const linkClickHandler = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement
    if (isHTMLAnchor(target) || isHTMLAnchor(target.parentElement)) {
      setMenuVisible(false)
    }
  }

  const buttonClickHandler = () => navigate(
    buttonLink, 
    { state: { prevPath: pathname } }
  )

  const desktopMenu = (
    <aside 
      className={`
        z-50 fixed left-0 right-0
        transform transition-transform ease-in-out duration-300
        ${visible ? 'translate-y-0' : '-translate-y-menu'}
      `}
      onClick ={linkClickHandler}
    >
      <div className="Menu bg-primary text-white px-m grid grid-cols-12 gap-m">
        <div className="col-span-9 flex flex-col justify-between">
          <div className="py-xxs" style={{ width: '92vw' }}>
            <Nav
              inMenu
              logo={logo}
              menuLogo={menuLogo}
              isLogoClickable={isLogoClickable}
              showHamburgerIcon={showHamburgerIcon}
              buttonText={t('common:close')}
              onButtonClick={() => setMenuVisible(false)}
            />
          </div>
          <div className="pb-m">
            <LinksGrid
              linksColumns={menuColumns}
              textColor="text-white"
              borderColor="border-white border-opacity-alt"
              linkHoverColor="text-white"
            />
          </div>
        </div>
        <div className="col-span-3">
          {photo && (
            <ImageElement
              image={photo}
              layout="fullWidth"
              aspectRatio={1}
              alt={photo.description || photo.name}
              className="max-w-none"
              style={imageStyle}
              options={imageQualityOptions}
            />
          )}
          {videoUrl && (
            <video 
              autoPlay muted loop playsInline 
              className="Video overflow-hidden flex object-cover max-w-none"
              style={{...imageStyle, ...videoStyle}}
            >
              <source
                src={videoUrl}
                type="video/mp4"
              />
            </video>
          )}
          <div className="pt-s pb-m FontM">
            <p>{contactName}</p>
            <p>{t('common:mobile')}: {contactPhone}</p>
            <p className='cursor-pointer hover:opacity-60'><a href={`mailto: ${contactEmail}`}>{contactEmail}</a></p>
            <ArrowButton className="pt-xxs" title={calendlyText} url={calendlyUrl} direction={ArrowDirection.OBLIQUE} colorSet={ArrowColor.WHITE} />
          </div>
        </div>
      </div>
    </aside>
  )

  const mobileMenu = (
    <aside 
      className={`
        z-50 fixed left-0 right-0 h-screen
        transform transition-transform ease-in-out duration-300
        ${visible ? 'translate-x-0' : 'translate-x-full'}
      `}
      onClick ={linkClickHandler}
    >
      <div className="Menu h-full bg-primary text-white">
        <div className="mx-m py-mobile-s">
          <Nav
            inMenu
            setMenuVisible={setMenuVisible}
            logo={logo}
            menuLogo={menuLogo}
            isLogoClickable={isLogoClickable}
            showHamburgerIcon={showHamburgerIcon}
            buttonText={buttonText}
            onButtonClick={buttonClickHandler}
            buttonType={buttonType}
          />
        </div>
        <div
          ref={sliderRef}
          onMouseDown={mouseDownHandler}
          onMouseLeave={mouseLeaveOrUpHandler}
          onMouseUp={mouseLeaveOrUpHandler}
          onMouseMove={mouseMoveHandler}
          className={`overflow-auto ${sliderState.isDown ? 'cursor-grabbing' : 'cursor-default'}`}
        >
          <div className="ml-m w-mobile-menu-grid flex">
            <LinksGrid
              linksColumns={menuColumns}
              textColor="text-white"
              borderColor="border-white"
              linkHoverColor="text-black"
            />
            <div className="pl-mobile-foot-gap"></div>
            <div>
              <div className="w-screen relative">
                {photo && (
                  <ImageElement
                    image={photo}
                    layout="fullWidth"
                    aspectRatio={1}
                    alt={photo.description || photo.name}
                    className="w-full"
                    options={imageQualityOptions}
                  />
                )}
                {videoUrl && (
                  <video 
                    autoPlay muted loop playsInline 
                    className="Video overflow-hidden flex object-cover w-full"
                    style={videoStyle}
                  >
                    <source
                      src={videoUrl}
                      type="video/mp4"
                    />
                  </video>
                )}
              </div>
              <div className="pt-mobile-s pb-mobile-m pl-mobile-xs FontM">
                <p>{contactName}</p>
                <p>{t('common:mobile')}: <a href={`tel: ${contactPhone}`}>{contactPhone}</a></p>
                <p><a href={`mailto: ${contactEmail}`}>{contactEmail}</a></p>
                <ArrowButton className="pt-mobile-xxs" title={calendlyText} url={calendlyUrl} direction={ArrowDirection.OBLIQUE} colorSet={ArrowColor.WHITE} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  )

  return isMobile ? mobileMenu : desktopMenu
}

export default Menu

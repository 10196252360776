export enum ArrowDirection {
  UP = 'up',
  DOWN = 'down',
  RIGHT = 'right',
  OBLIQUE = 'oblique'
}

export enum ArrowColor {
  BLACK = 'black',
  WHITE = 'white',
  PRIMARY = 'primary'
}

export enum DynamicSitesCategory {
  BLOG = 'blog',
  WORK = 'work'
}

export enum DynamicSitesPath {
  BLOG = 'allKontentItemBlogArticle.nodes',
  WORK = 'allKontentItemCaseStudy.nodes'
}

export enum BlogCategory {
  ALL = 'All',
  'ecommerce' = 'Ecommerce',
  'business' = 'Business',
  'user-experience' = 'User experience',
  'user-interface' = 'User interface'
}

export enum ImageQuality {
  DEFAULT = 85,
  OPTIMIZED = 70
}

export enum ImageAspectRatio {
  SQUARE = '1 / 1',
  RECTANGLE = '3 / 2'
}

export enum ButtonType {
  PRIMARY = 'ButtonPrimary',
  SECONDARY = 'ButtonSecondary'
}

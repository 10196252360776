import * as React from 'react'
import LinksColumn, { LinksColumnColors } from './LinksColumn'
import { LinksGrid as LinksGridClass } from './model'
import './style.scss'
import { AnimationService } from '../../common/AnimationService'

interface LinksGridProps extends LinksGridClass, LinksColumnColors {
  animate?: boolean
}

const LinksGrid: React.FC<LinksGridProps> = ({
  linksColumns, 
  textColor,
  borderColor,
  linkHoverColor,
  animate = false
}) => {
  const columnsRefs = React.useRef<Array<HTMLDivElement>>([])
  React.useEffect(() => {
    if (animate) {
      AnimationService.slideSequenceUp(columnsRefs.current)
    }
  }, [animate])

  const columnsCount = linksColumns?.length
  const gridColsCount = ['grid-cols-3', 'grid-cols-4']

  return (
    <div className="LinksGrid w-full"
    >
      <section className={`grid ${gridColsCount[columnsCount - 3]} gap-mobile-foot-gap lg:gap-m`}>
        {linksColumns.map((column, i) => (
          <LinksColumn 
            title={column.title}
            links={column.links}
            textColor={textColor}
            borderColor={borderColor}
            linkHoverColor={linkHoverColor}
            key={i}
            ref={(el: HTMLDivElement) => columnsRefs.current[i] = el} 
          />
        ))}
      </section>
    </div>
  )
}

export default LinksGrid

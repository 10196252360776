import * as React from 'react'
import { LanguageContext } from '../../common/Context'
import SeoComponent from '../SeoComponent'
import { SeoData } from '../SeoComponent/model'
import { AnimationService } from '../../common/AnimationService'
import { getDataFromURL } from '../../common/InboundService'
import './style.scss'

export interface LayuotProps {
  seoData: SeoData
  language: string
  setLanguage: React.Dispatch<React.SetStateAction<string>>
}

const Layout: React.FC<LayuotProps> = ({
  seoData,
  language,
  setLanguage,
  children,
}) => {
  AnimationService.init()

  React.useEffect(() => {
    getDataFromURL()
  }, [])

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <SeoComponent {...seoData} />
      {children}
    </LanguageContext.Provider>
  )
}

export default Layout

import * as React from 'react'

export interface Options {
  threshold?: number
}

/**
 * @param threshold number of pixels that the page needs to be scrolled from the top to flag as active
 */
export const useScroll = ({threshold = 0}: Options) => {
  const [isThresholdPassed, setIsThresholdPassed] = React.useState(false)

  const toggleVisibility = React.useCallback(() => {
    if (window.pageYOffset >= threshold) {
      setIsThresholdPassed(true)
    } else {
      setIsThresholdPassed(false)
    }
  }, [threshold])

  React.useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)

    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [toggleVisibility])

  const scrollTo = (target: HTMLElement | null) => {
    if (target instanceof HTMLElement) {
      target.scrollIntoView({
        behavior: 'smooth', 
        block: 'start', 
        inline: 'nearest'
      })
    }
  }

  return {
    isThresholdPassed,
    scrollTo
  }
}
export const getDataFromURL = (): void => {
  let inbound = '';

  const browsers = [
    'www.google.com',
    'www.bing.com',
    'www.yahoo.com',
    'www.baidu.com',
    'www.yandex.com',
    'duckduckgo.com',
    'search.aol.com',
    'www.ask.com',
    'www.wolframalpha.com',
    'archive.org',
    'www.startpage.com',
    'www.qwant.com',
    'search.creativecommons.org',
    'gibiru.com',
    'www.ecosia.org',
    'www.dogpile.com',
    'www.excite.com',
    'www.goodsearch.com',
    'www.lycos.com',
    'metager.org',
  ];

  const valueExists = (value: null | string): boolean => value != null && value !== '' && value !== undefined;

  const socialMedias = [
    'facebook',
    'instagram',
    'tiktok',
    'twitter',
    'linkedin',
  ];

  let referrer = '';

  if (valueExists(document.referrer)) {
    referrer = new URL(document.referrer).hostname;

    if (!referrer.includes('extrahut')) {
      if (browsers.find(domain => referrer.includes(domain))) {
        inbound = 'Inbound - Organic';
      } else if (referrer.includes('clutch')) {
        inbound = 'Inbound - Clutch';
      } else if (socialMedias.find(utm => referrer.includes(utm))) {
        inbound = 'Inbound - Social Media';
      } else {
        inbound = 'Inbound - Referral';
      }

      localStorage.setItem('inbound', inbound);
    }
  } else {
    inbound = 'Inbound - Direct';
    localStorage.setItem('inbound', inbound);
  }
};

import * as React from 'react'
import GatsbyLink from 'gatsby-link'
import { IsMobileContext } from '../../common/Context'
import { ArrowDirection, ArrowColor } from '../../common/typings/enums'
import { useScroll } from '../../hooks/useScroll'
import ArrowUp from '../../images/arrow_up.svg'
import ArrowDown from '../../images/arrow_down.svg'
import ArrowRight from '../../images/arrow_right.svg'
import ArrowOblique from '../../images/arrow_45.svg'
import './style.scss'

export interface ArrowButtonProps {
  title: string
  direction: ArrowDirection
  url?: string
  className?: string
  scrollToId?: string
  colorSet?: ArrowColor
}

const ArrowButton: React.FC<ArrowButtonProps> = ({ title, url, direction, className, scrollToId, colorSet = ArrowColor.PRIMARY }) => {

  const { isMobile } = React.useContext(IsMobileContext)
  const arrowHeight = isMobile ? '6vw' : '1.5vw'
  const arrowStyle = 'ml-mobile-xs text-primary hover:text-black lg:ml-xs'
  const isRelativeUrl = (url: string): boolean => /^\/(?!\/)/.test(url)
  const [element, setElement] = React.useState(typeof document !== 'undefined' ? document.getElementById(scrollToId ?? '') : null)
  const { scrollTo } = useScroll({})

  React.useEffect(() => {
    if (scrollToId) {
      setElement(typeof document !== 'undefined' ? document.getElementById(scrollToId) : null)
    }
  }, [scrollToId])

  const arrowDirection = (direction: ArrowDirection, arrowStyle: string) => {
    switch (direction) {
    case ArrowDirection.UP:
      return <ArrowUp className={arrowStyle} style={{ height: arrowHeight }} />
    case ArrowDirection.DOWN:
      return <ArrowDown className={arrowStyle} style={{ height: arrowHeight }} />
    case ArrowDirection.RIGHT:
      return <ArrowRight className={arrowStyle} style={{ height: arrowHeight }} />
    case ArrowDirection.OBLIQUE:
      return <ArrowOblique className={arrowStyle} style={{ height: arrowHeight }} />
    }
  }

  return (
    <button
      className={`ArrowButton ${colorSet} ${className || ''} FontM cursor-pointer`}
      onClick={() => scrollToId ? scrollTo(element) : null}
    >
      {url && isRelativeUrl(url) ?
        <GatsbyLink
          className='flex flex-row'
          to={url}
        >
          <p className='block'>{title}</p>
          {arrowDirection(direction, arrowStyle)}
        </GatsbyLink> :
        <a
          href={url}
          target='_blank'
          rel="noreferrer noopener"
          className='flex flex-row'
        >
          <p className='block'>{title}</p>
          {arrowDirection(direction, arrowStyle)}
        </a>
      }
    </button>
  )
}

export default ArrowButton

import * as React from 'react'
import { MobileContextType } from '../hooks/useMobile'

export const IsMobileContext = React.createContext<MobileContextType>({
  isMobile: undefined
})

type LanguageContextType = {
  language: string;
  setLanguage?: React.Dispatch<React.SetStateAction<string>>
}
export const LanguageContext = React.createContext<LanguageContextType>({
  language: 'en',
  setLanguage: undefined
})

import * as React from 'react'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { IsMobileContext } from '../../../common/Context'
import HamburgerIcon from '../../../images/hamburger.svg'
import BigX from '../../../images/x_big.svg'
import Button from '../../Button'
import Link from '../../Link'
import { ButtonType } from '../../../common/typings/enums'
import './style.scss'

export interface NavProps {
  inMenu?: boolean
  setMenuVisible?: (newState: boolean) => void
  logo: ImageItem
  menuLogo: ImageItem
  isLogoClickable: boolean
  showHamburgerIcon: boolean
  buttonText: string
  onButtonClick: () => void
  buttonType?: ButtonType
  avatarPhoto?: ImageItem
  avatarVideoUrl?: string
  darkMode?: boolean
}

const Nav: React.FC<NavProps> = ({
  inMenu,
  setMenuVisible,
  logo,
  menuLogo,
  isLogoClickable,
  showHamburgerIcon,
  buttonText,
  onButtonClick,
  buttonType,
  avatarPhoto,
  avatarVideoUrl,
  darkMode = false,
}) => {
  const { isMobile } = React.useContext(IsMobileContext)

  const bigX = setMenuVisible
    ? <BigX className="BigX w-full cursor-pointer" style={{ height: '12vw' }} onClick={() => setMenuVisible(false)} />
    : null

  const logoHeight = isMobile ? '10vw' : '2.5vw'
  const hamburgerMenuHeight = isMobile ? '2.8vw' : '0.7vw'

  const typeOfButton = (
    buttonType ? buttonType : 
      darkMode ? 'ButtonPrimaryDark' : 'ButtonPrimary'
  )
  const buttonClass = inMenu
    ? 'ButtonSecondary FontS block w-full'
    : `${typeOfButton} FontS whitespace-nowrap`

  const logoUrl = (inMenu || darkMode) ? menuLogo.url : logo.url

  return (
    <nav className="Nav flex justify-between">
      <div className="Logo flex items-center">
        {isLogoClickable ? (
          <Link url='/'>
            <img src={logoUrl} className="Logo" style={{ height: logoHeight }} />
          </Link>
        ) : (
          <div>
            <img src={logoUrl} className="Logo" style={{ height: logoHeight }} />
          </div>
        )}
      </div>
      {showHamburgerIcon && !inMenu && setMenuVisible && (
        <div
          className={`Hamburger ${darkMode ? 'dark' : ''} cursor-pointer flex items-center`}
          onClick={() => setMenuVisible(true)}
        >
          <HamburgerIcon className="w-full" style={{ height: hamburgerMenuHeight }} />
        </div>
      )}
      <div className="flex items-center z-10" style={{transform: 'translate3d(0,0,0)'}}>
        {inMenu && isMobile
          ? bigX
          : <>
            {!inMenu && avatarPhoto && (
              <img 
                src={avatarPhoto.url}
                className="rounded-full object-cover"
                style={{width: logoHeight, height: logoHeight}}
                alt={avatarPhoto.description || 'avatar'}
              />
            )}
            {!inMenu && avatarVideoUrl && (
              <div style={{width: logoHeight}}>
                <video 
                  autoPlay muted loop playsInline 
                  className="rounded-full object-cover"
                  style={{width: logoHeight, height: logoHeight}}
                >
                  <source
                    src={avatarVideoUrl}
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
            <Button
              className={buttonClass}
              title={buttonText}
              onClick={onButtonClick}
            />
          </>
        }
      </div>
    </nav>
  )
}

export default Nav

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export class AnimationService {
  private static animationTime = 0.5
  private static inSequenceDelay = '-=0.3'
  private static scrollTriggerStart = 'top 100%'
  private static scrollTriggerEnd = 'top 80%'
  private static scrollUpVh = 15
  private static isInitialized = false

  static init() {
    if (this.isInitialized) {
      if (typeof window !== 'undefined') {
        this.refreshScrollTrigger()
      }
      return
    }
    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger)
    }
    gsap.defaults({ duration: this.animationTime })
    this.isInitialized = true
  }

  static refreshScrollTrigger() {
    ScrollTrigger.refresh(true)
  }

  static slideRight(element: HTMLElement | null, shiftPercent: number, scrollTriggered = true) {
    if (element) {
      gsap.from(
        element, 
        {
          ...this.getFadeInRightOptions(shiftPercent),
          scrollTrigger: scrollTriggered ? this.getScrollTriggerOptions(element) : undefined
        }
      )  
    }
  }

  static slideUp(element: HTMLElement | null, scrollTriggered = true) {
    if (element) {
      gsap.from(
        element, 
        {
          ...this.getFadeInUpOptions(),
          scrollTrigger: scrollTriggered ? this.getScrollTriggerOptions(element) : undefined
        }
      )  
    }
  }

  static switchUp(element: HTMLElement | null) {
    if (element) {
      return gsap.to(
        element, 
        {
          ...this.getSwitchUpOptions(),
        }
      )  
    }
  }

  static parallax(background: HTMLElement | null, layer: HTMLElement | null, speed: number) {
    if (background && layer) {
      gsap.to(layer, {
        yPercent: -speed,
        ease: 'none',
        scrollTrigger: {
          trigger: background,
          scrub: true,
        },
      })
    }
  }

  static fadeIn(element: HTMLElement | null, scrollTriggered = true) {
    if (element) {
      gsap.from(
        element, 
        {
          opacity: 0,
          scrollTrigger: scrollTriggered ? this.getScrollTriggerOptions(element) : undefined
        }
      )  
    }
  }

  static slideSequenceRight(elements: (HTMLElement | null)[], shiftPercent: number, delay = 0) {
    const actualElements = elements.filter(element => element) as HTMLElement[]
    if (actualElements.length) {
      const tl = gsap.timeline({
        delay,
        scrollTrigger: this.getScrollTriggerOptions(actualElements[0])
      })
      actualElements.map((element, i) => {
        tl.from(element, this.getFadeInRightOptions(shiftPercent), this.tweenDelay(i))
      })
      return tl
    }
  }

  static slideSequenceUp(elements: (HTMLElement | null)[], scrollTriggered = true) {
    const actualElements = elements.filter(element => element) as HTMLElement[]
    if (actualElements.length) {
      const tl = gsap.timeline({
        scrollTrigger: scrollTriggered ? this.getScrollTriggerOptions(actualElements[0]) : undefined
      })
      actualElements.map((element, i) => {
        tl.from(element, this.getFadeInUpOptions(), this.tweenDelay(i))
      })
    }
  }

  static rotateSequence(elements: (HTMLElement | null)[], scrollTriggered = true) {
    const actualElements = elements.filter(element => element) as HTMLElement[]
    if (actualElements.length) {
      const tl = gsap.timeline({
        scrollTrigger: scrollTriggered ? this.getScrollTriggerOptions(actualElements[0]) : undefined
      })
      actualElements.map((element, i) => {
        tl.from(element, this.getFadeInRotateOptions(), this.tweenDelay(i))
      })
    }
  }

  static horizontalLoop(orgBox: HTMLElement, shadowBox: HTMLElement, config: GSAPTimelineVars, reversed = false) {
    const tl = gsap.timeline({
      repeat: config.repeat, 
      paused: config.paused, 
      defaults: { ease: 'none' },
    })
    const pixelsPerSecond = (config.speed || 1) * 100
    const width = orgBox.clientWidth + parseFloat(getComputedStyle(orgBox)[reversed ? 'marginLeft' : 'marginRight'])
    const duration = width / pixelsPerSecond 
    tl.fromTo(orgBox, { x: 0 }, { x: `${reversed ? width : -width}px`, duration }, 0)
      .fromTo(orgBox, { x: `${reversed ? -width : width}px` }, { x: 0, duration, immediateRender: false }, '>')
      .fromTo(shadowBox, { x: 0 }, { x: `${reversed ? width * 2 : -width * 2}px`, duration: 2 * duration }, 0)
    tl.progress(1, true).progress(0, true) // pre-render for performance
    return tl
  }
  
  static isInView(element: HTMLElement | null) {
    if (element) {
      const box = element.getBoundingClientRect()
      return box.top < window.innerHeight && box.bottom >= 0
    }
  }

  private static getScrollTriggerOptions(el: HTMLElement) {
    return {
      trigger: el,
      start: this.scrollTriggerStart,
      end: this.scrollTriggerEnd,
      markers: false,
      scrub: 1
    }
  }

  private static getFadeInRightOptions(xPercent: number) {
    return {
      opacity: 0,
      xPercent: -xPercent,
    }
  }

  private static getFadeInUpOptions() {
    return {
      opacity: 1,
      y: window.innerHeight / this.scrollUpVh
    }
  }

  private static getSwitchUpOptions() {
    return {
      duration: 0.2,
      paused: true,
      yPercent: -50,
    }
  }

  private static getFadeInRotateOptions() {
    return {
      opacity: 1,
      rotation: '330_short'
    }
  }

  private static tweenDelay(tweenIndex: number) {
    return tweenIndex === 0 ? '' : this.inSequenceDelay
  }
}
import * as React from 'react'
import { navigate } from 'gatsby'
import Nav from './Nav'
import Menu from './Menu'
import { ButtonType } from '../../common/typings/enums'
import { LinksGrid as LinksGridClass } from '../LinksGrid/model'
import { Navbar as NavbarClass } from './model'
import './style.scss'


interface NavbarProps extends LinksGridClass, NavbarClass {
  buttonType?: ButtonType
  darkMode?: boolean
}

const Navbar = React.forwardRef<HTMLDivElement, NavbarProps>((props: NavbarProps, ref) => {
  const {linksColumns,
    logo,
    contactData,
    menuLogo,
    calendlyText,
    calendlyUrl,
    isLogoClickable,
    showHamburgerIcon,
    buttonText,
    buttonLink,
    buttonType,
    avatarPhoto,
    avatarVideoUrl,
    pathname,
    darkMode,
  } = props
  const [menuVisible, setMenuVisible] = React.useState(false)
  const [backdropActive, setBackdropActive] = React.useState(false)
  React.useEffect(() => {
    if (!menuVisible) {
      setBackdropActive(false)
    }
  }, [menuVisible])

  const clickHandler = () => {
    if (Number(buttonLink)) {
      navigate(Number(buttonLink))
    } else {
      navigate(
        buttonLink, 
        { state: { prevPath: pathname } }
      )
    }
  }

  return (
    <div className="Navbar fixed top-0 left-0 right-0 opacity-1 z-50">
      <Menu
        visible={menuVisible}
        setMenuVisible={setMenuVisible}
        linksColumns={linksColumns}
        logo={logo}
        menuLogo={menuLogo}
        isLogoClickable={isLogoClickable}
        showHamburgerIcon={showHamburgerIcon}
        contactData={contactData}
        calendlyText={calendlyText}
        calendlyUrl={calendlyUrl}
        buttonText={buttonText}
        buttonLink={buttonLink}
        buttonType={buttonType}
        pathname={pathname}
      />
      <div
        className={`z-40 fixed inset-0 bg-black opacity-40
          ${menuVisible ? 'block' : 'hidden'}
        `}
        onClick={() => setMenuVisible(false)}
        onMouseEnter={() => { 
          if (!backdropActive) {
            setBackdropActive(true)
          } else {
            setMenuVisible(false)
          }
        }}
      ></div>
      <div 
        className="Nav-wrapper px-mobile-xs lg:px-0 lg:mx-m py-mobile-xxs lg:py-xxs border-b border-separator border-opacity-separator"
        ref={ref}
      >
        <Nav
          setMenuVisible={setMenuVisible}
          logo={logo}
          menuLogo={menuLogo}
          isLogoClickable={isLogoClickable}
          showHamburgerIcon={showHamburgerIcon}
          buttonText={buttonText}
          onButtonClick={clickHandler}
          buttonType={buttonType}
          avatarPhoto={avatarPhoto}
          avatarVideoUrl={avatarVideoUrl}
          darkMode={darkMode}
        />
      </div>
      <div className="pt-mobile-xxs lg:pt-xxs"></div>
    </div>
  )
})
Navbar.displayName = 'Navbar'

export default Navbar

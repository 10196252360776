import { graphql, useStaticQuery } from 'gatsby'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

const linksGridElements = (linksGridQueryData: KenticoFooter): LinksColumn[] => {
  const COLUMNS_NUMBER = 4
  const elements = []
  for (let i = 1; i <= COLUMNS_NUMBER; i++) {
    const element = {
      title: _get(linksGridQueryData, `column_${i}_title.value`, ''),
      links: _get(linksGridQueryData, `column_${i}_items.value`, [])
        .filter(Boolean)
        .map((linkElement: KenticoLinkOrText) => {
          return {
            title: _get(linkElement, 'elements.text.value', ''),
            url: _get(linkElement, 'elements.link.value', '')
          }
        })
    }
    elements.push(element)
  }
  return elements
}

interface KenticoLinkElement extends KontentItem {
  elements: {
    text: KontentTextElement
    link: KontentTextElement
  }
}
interface KenticoTextElement extends KontentItem {
  elements: {
    text: KontentRichTextElement
  }
}
type KenticoLinkOrText = KenticoLinkElement | KenticoTextElement

/* Kentico model: Footer */
export interface KenticoFooter extends KontentItem {
  column_1_title: KontentTextElement
  column_1_items: KenticoLinkOrText[]
  column_2_title: KontentTextElement
  column_2_items: KenticoLinkOrText[]
  column_3_title: KontentTextElement
  column_3_items: KenticoLinkOrText[]
  column_4_title: KontentTextElement
  column_4_items: KenticoLinkOrText[]
}

interface linkElement {
  url: string
  title: string
}
export interface LinksColumn {
  title: string
  links: linkElement[]
}
export class LinksGrid {
  linksColumns: LinksColumn[]

  constructor(data: KenticoFooter) {
    this.linksColumns = linksGridElements(data)
  }
}

const linksGridFragment = graphql`
query LinksGridQuery {
  allKontentItemPage {
    nodes {
      elements {
        footer {
          value {
            ... on kontent_item_footer {
              elements {
                ...kontent_item_footer_elementsFragment
              }
              preferred_language
              system {
                codename
              }
            }
          }
        }
      }
      system {
        codename
      }
    }
  }
}

fragment footer_column_itemFragment on kontent_item_modular_content_element_value {
  value {
    ... on kontent_item_link_element {
      elements {
        link {
          value
        }
        text {
          value
        }
      }
    }
    ... on kontent_item_text_element {
      elements {
        text {
          value
        }
      }
    }
  }
}`

export const linksGridElementsFragment = graphql`
  fragment kontent_item_footer_elementsFragment on kontent_item_footer_elements {
    column_1_items {
      ...footer_column_itemFragment
    }
    column_1_title {
      value
    }
    column_2_items {
      ...footer_column_itemFragment
    }
    column_2_title {
      value
    }
    column_3_items {
      ...footer_column_itemFragment
    }
    column_3_title {
      value
    }
    column_4_items {
      ...footer_column_itemFragment
    }
    column_4_title {
      value
    }
  }
`

export const useLinksGridData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(linksGridFragment)
  const linksGridData: KenticoFooter = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language, 'footer')
  return new LinksGrid(linksGridData)
}